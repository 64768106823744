.Client header{
  /* background: #4770AC!important; */
  background: linear-gradient(white, white);
  /* background: linear-gradient(#4770AC, #49769d); */
  /* box-shadow: 0px 1px 2px #d1d1d14f;  filter: brightness(99%); */
  left: 0!important;
  width: 100%;
}
.map-page.Client header{
  left: 73px!important;
  width: calc(100% - 73px);
}
@media (max-width: 900px) {
  .map-page.Client header{
    left: 0!important;
    width: 100%;
  }
}
header {
  z-index: 1001;
}
.Client header .MuiToolbar-root{
  padding-left: 0;
  min-height: 64px!important;
  height: 64px!important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Client .booth-list-slider .MuiDrawer-paper{
  top: 0;
  height: calc(100% - 0px)
}

header svg{
  background-color: unset!important;
}
header .MuiButtonGroup-root{
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 8%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 3%);
}
#background-modal svg{
  background-color: unset!important;
}
.company-profile-viewer{
  z-index: 1101
}

.Client .sideview{
    z-index: 10004!important;
    top: 68px!important;
}
.Client .sideview.unassigned{
  z-index: 10005!important;
}

.Client .sideview.assigned {
  z-index: 10005!important;
}

.siderightview{
  position: fixed;
    top: 110px;
    right: 7px;
    z-index: 1004;
}
.siderightview .MuiAccordion-root{
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}
.top-0 .MuiDrawer-paper{
  
  background: #f5f5f5;
  top: 0!important;
  height: calc(100% - 0px)!important;
}
.main-container{
  display:flex;
  position: relative;
}
.toolbar{
  /* position: fixed;
  left:0;
  top:64px; */
  /* width: 170px; */
  height: 100%;
  z-index: 1000;
  /* background: #e0e0e0; */
  padding: 25px 10px;
  /* border-right: 3px solid #707070; */
}
.right-toolbar{
  /* position: fixed;
  left:0;
  top:64px; */
  /* width: 170px; */
  height: 100%;
  z-index: 1000;
  /* background: #e0e0e0; */
  padding: 25px 10px;
  /* border-right: 3px solid #707070; */
}
.canvas-viewer{ 
  padding-left: 0;
  padding-top:51px
}
.canvas-container{ 
  padding-left: 0;
  padding-top:0!important
}
[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  cursor: move;
}
.furniture{
  /* text-align: left; */
}
/* .mouseModal{
  position: fixed;
  background: white;
  border-radius: 5px;
  padding: 20px 10px;
  z-index: 10005;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: none;
} */
.client-control {
  position: absolute!important;
  left: unset!important;
  top: 69px!important;
  right: 10px;
}
.client.legend-option {
  right: 50px;
  top: 110px;
  left: unset;
}
.client.boothlist-option {
  right: 50px;
  top: 73px;
  left: unset;
}
.boothlist-option {
  /* border: 1px solid #29446438; */
  color: #294464;
  background: white;
  position: fixed;
  top: 105px;
  left: 150px;
  width: 270px;
  border-radius: 3px;
  padding: 7px;
  box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.disabled{
  opacity: 0.5;
}
.enabled{
  opacity: 1!important;
}
.layertext .MuiTypography-root{
  font-size: 12px;
  word-break: break-word;
}
.layeravada {
  min-width: 0 !important;
  margin-right: 15px;
}
.boot-cat-select{
  box-shadow:none!important;
}
.render-booth-container{
  margin:auto
}
.sketch-picker{
  margin: auto;
  width: 300px!important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px!important;
}
div[id^=font-picker] .dropdown-button {
  background: #b1b1b130!important;
}


.price-modal .MuiTableCell-root{
  border-right: 1px solid rgb(187, 187, 187);
  border-left: 1px solid rgb(187, 187, 187);
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding:10px
}
.price-modal .MuiTableRow-root:last-child td, .price-modal .MuiTableRow-root:last-child th {
  border-right: 1px solid rgb(187, 187, 187);
  border-left: 1px solid rgb(187, 187, 187);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.color-table .MuiTableCell-root{
  border-right: 1px solid rgb(187, 187, 187);
  border-left: 1px solid rgb(187, 187, 187);
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding:10px
}
.color-table .MuiTableRow-root:last-child td, .price-modal .MuiTableRow-root:last-child th {
  border-right: 1px solid rgb(187, 187, 187);
  border-left: 1px solid rgb(187, 187, 187);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.color-table thead tr th{
  background-color: #4E7090;
  color:white
}
.color-table tbody tr th{
  background-color: #a4c0da;
}
.group-pannel > .MuiBox-root{
  padding-left: 0;
  padding-right: 0;
}
.group-pannel .subtitle{
  background-color: #4E7090;
  color:white;
  padding: 5px 10px
}
.item-label{
  display:flex;
  align-items:center;
  padding-left: 10px;
  background-color: #a4c0da;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.item-field{
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.group-color-picker{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%)
}
.item-color{
  display:flex;
  align-items: center;
}
.color-icon{
  width:60px;
  height:30px;
  border:2px solid rgba(224, 224, 224, 1);
  background:rgba(224, 224, 224, 1);
  margin-right: 5px;
}

.pricing-table  thead tr th{
  background-color: #4E7090;
  color:white
}
/* .pricing-table  tbody tr th{
  background-color: #a4c0da;
} */
.MuiButton-root {
  text-transform:unset!important
}
.MuiButtonBase-root{
  text-transform:unset!important
}
.preview-image{
  width:150px;
  height:150px;
  border: 1px solid #e1e1e1;
    margin-top: 10px;
}
.preview-image img{
  width: 100%;
  height: 100%;
  object-fit:cover
}
.preview-contain-image{
  width:150px;
  height:150px;
  border: 1px solid #e1e1e1;
    margin-top: 10px;
  display:flex;
}
.preview-contain-image img{
  width: 100%;
  object-fit:contain
}

.MuiDialogTitle-root {
  background: #3b6c9b;
  color: white;
  padding: 13px 24px!important;
}
.carousel-item{
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
  margin: 16px 10px;
  height:150px
}
.carousel-item img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.carousel-item .video{
  object-fit: cover;
  width: 100%!important;
  height: 100%!important;
}
.company-profile-viewer .video{
  width: 100%!important;
  height: 215px!important;
}
.company-profile-viewer .full-cover{
  width: 100%!important;
  height: 293px!important;
}
.company-profile-viewer .bg{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position:relative;
}
/* .company-profile-viewer .bg::after{
  content:'';
  width: 100%;
  height:100%;
  position: absolute;
  left:0;
  top:0;
  opacity:0.5;
  background:white
} */
.bg .bgImg{
  object-fit: cover;
    position: absolute;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.company-profile-viewer .carousel-item {
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
  margin: 0;
  height: 300px;
}
.company-profile-viewer .react-multi-carousel-list{
  padding-bottom:20px
}
.categoryBox{
  max-height: 400px;
  overflow-y: auto;
}
.backgroundBg{
  background-repeat: no-repeat;
  background-size: cover;
}
.header-carousel-item img{
  filter: brightness(99%);
}
.header-carousel-item img{
  height: 57px;
  width: auto;
  max-width: 100%;
}
.ajax-status{
  position: fixed;
  right: 8px;
  bottom: 8px;
}
#bg-img-canvas{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}

.controls.public{
  top: 10px;
  display: flex;
  right: 20px;
  left: unset!important;
}

.bubble-btn{
  border: 1px solid #dadce0!important;
  border-radius: 19px!important;
  padding: 3px 8px!important;
  color:#202124!important;
  background: white!important;
}
.video-photo-hover .react-multiple-carousel__arrow{
  display: none;
}
.video-photo-hover:hover .react-multiple-carousel__arrow{
  display: block;
}

.carousel-thumb{
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
  margin: 10px;
  height:152px;
  cursor: pointer;
}
.exhibitor-product-div .product-title{
  font-weight: bold;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 5px;
}
.exhibitor-product-div .product-download{
  font-size: 14px;
  text-decoration: none;
  color:#1a73e8
}
.exhibitor-details-popup{
  position:fixed;
  top:0;
  width: 360px;
  max-height: 600px;
  overflow: auto;
  z-index: 100000;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 51%), 0px 4px 5px 0px rgb(0 0 0 / 15%), 0px 1px 10px 0px rgb(0 0 0 / 48%);
  left: 419px;
  background: #fafafa;
  top: 50%;
  transform: translateY(-50%);
}
.exhibitor-details-popup.call-action{
  width: 100%;
  max-width: 640px;
}

.exhibitor-details-popup.event{
  width: 100%;
  max-width: 800px;
}
.exhibitor-details-close-btn{
  position: absolute!important;
  top: 8px!important;
  right: 8px!important;
  background: white!important;
}
.exhibitor-details-popup img{
  width: 100%;
  height: 230px;
  object-fit: cover;
}
.exhibitor-details-popup.call-action img{
  width: 100%;
  height: unset;
  object-fit: contain;
}

.exhibitor-details-popup .category-title, .exhibitor-details-popup .sub-title{
  font-size: 14px;
  color: #656565;
}
.round-icon-btn{
  color: white;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #1a73e8;
  text-decoration:none;
  background: #1a73e8;
}

.round-icon-btn.small{
  width: 22px;
  height:22px;
}
.exhibitor-details-description{
  max-height: 300px;
  overflow: auto;
}
.social-icon{
  width: 40px;
  display: flex;
  height: 40px;
  font-size: 24px;
  background: #ff671f;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  border-radius: 50%;
  margin: 0 4px;
}
.directory-header{
  font-size: 18px;
}
.search-div{
  width:100%;
  position:relative;
}

.search-div .MuiInputBase-root{
  width: calc(100% - 14px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02);
  border: none;
  padding: 6px 40px 6px 16px;
}

.search-div .search-inside{
  position: absolute;
  right: 64px;
  top:50%;
  transform: translateY(-50%);
  width: 36px;
}
.search-div .close-inside{
  position: absolute;
  right: 24px;
  top:50%;
  transform: translateY(-50%);
  width: 36px;
}
.left-menubar{
  width: 72px;
  height: 100vh;
  border-right: 1px solid #e1e1e1;
}
.left-menucontent{
  width:calc(100% - 72px);
}
.heat-play_button:disabled{
  opacity: 0.5;
}
.MuiPaper-outlined .show-more a.d-inline{
  display: inline!important;
}
.read-more{
  justify-content: center;
}

.featured-label{
  background:'#FF7F27';
  background: var(--secondaryColor);
  color: white;
  width: 100%;
  padding: 7px;
  font-weight: bold;
  border: none;
  box-sizing: border-box;
  line-height: 1;
  text-align: center;
}

.featured-label.small{
  padding: 3px;
  font-size: 14px;
}
.featured-label.feet-content{
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
  margin-bottom: 10px;
  border: none;
}
.exhibitor_card{
  overflow: hidden;
}
.exhibitor_card .featured-label.feet-content{
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  border: none;
  padding: 12px;
}
#xhbt-main-container .MuiPaper-outlined span a{
  display: inline!important;
  font-size: 11.5px!important;
}
.custom-indicator .MuiSvgIcon-root{
  width: 100%;
  height:100%
}
.sponsorship-images{
  margin-top:2px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sponsorship-image{
  height: auto;
  margin: 3px 2px 0px 0;
}
.sponsorships-logo{
  width: 100%;
  height:auto;
  max-height:140px;
  object-fit: cover;
}
.paragraph{
  margin: 0px 0px 5px 0px
}
.react-read-more-read-less, .react-read-more-read-less-more{
  text-decoration: underline;
  color:#0000ee;
}

.xhbt-data-box{
  padding-top: 20px;
  box-sizing: border-box;
  min-height: 700px;
}
@media (max-width: 900px) {
  .xhbt-data-box{
    padding-top: 30px;
  }
}
.xhbt-main-menu-main-container{
  display: block;
}
.xhbt-main-menu-burger-icon{
  display: none;
}
@media (max-width: 1400px) {
  .xhbt-main-menu-main-container{
    display: none;
  }
  .xhbt-main-menu-burger-icon{
    display: block;
  }
}
.contact-list p{
  margin-top: 0;
  margin-bottom:6px
}
.exhibitor-details-popup.press-releases{  
  width: 720px;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 30px);
}
.exhibitor-details-popup.press-releases .exhibitor-details-description{  
  max-height: unset;
}

.exhibitor-details-popup.event .exhibitor-details-description{
  max-height: unset;
}

@media (max-width: 560px) {
  .exhibitor-details-popup.press-releases{  
    width: 360px;
  }
}
@media (max-width: 360px) {
  .exhibitor-details-popup.press-releases{  
    width: 320px;
  }
}
.grey-img-bg {
  position: relative;
  width: 100%;
  height: 100%;
  min-height:calc(100vh - 64px)
}

.grey-img-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://mapbeta.goeshow.com/asset/grey-bg.jpg');
  background-size: contain;
  background-position: center;
  opacity: 0.15; /* Controls background opacity */
  z-index: -1;
}


.border-container{
  /* border:1px solid #c7c7c7; */
  min-height: 500px
}
.grid{
  display:flex;    
}
.grid .left{
  width: calc(100% - 320px);
  box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.1),0px 1px 4px 0px rgba(0,0,0,0.1);
  background:white;
}
.grid .right{    
  width: 310px;
  padding: 10px;
  margin-left: 10px;
  text-align: left;
  box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.1),0px 1px 4px 0px rgba(0,0,0,0.1);
  box-sizing: border-box;
  background:white;
}

.table-list .header-item{
  background:#e0e0e0
}
.table-list .MuiListItem-root:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.table-list .MuiListItem-root {
  border-bottom: 1px solid #e0e0e0;
}
.table-list .MuiListItem-root:first-child {
  border-top: 1px solid #e0e0e0;
}
.table-list th svg{
  stroke:#42454f
}

.table-list th, .table-list td{
  padding: 10px!important;
}

.exhibitor-profile-page .xhbt-data-box{
  width: 80%;
  max-width: 1600px;
}
@media (max-width: 768px) {
  .exhibitor-profile-page .xhbt-data-box{
    width: 100%;
  }
  .grid{
    display:block;    
  }
  .grid .left{
    width: 100%
  }
  .grid .right{
    width: 100%;    
    margin-left: 0;
    margin-top: 10px;
  }
}

.sticky-header th {
  position: sticky;
  z-index: 1;
}
.v-th{
  background-color: #AEC0D9;
}
.color-table.small .MuiTableCell-root{
  padding:6px
}
.payment-form .price{
  font-size: 24px;
    margin-bottom: 10px;
    color: #777777;
}
.auto-combine-label .MuiTypography-root{
  font-size: 13px;
}

.avatar-div{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.event-card p, .event-card h4, .event-card h3{
  margin:0;
  line-height: 1.2;
  margin-bottom:5px
}
.event-date{
  font-weight: bold;
  font-size: 10px;
  text-align: left;
}
.error-content{
  font-weight: bold;
}
.footer-text{
  background: var(--navbarColor) !important;
  color: white;
  font-size: 13.5px;
  padding: 7px 10px;
}
.footer-items{
  display: flex;
  padding: 5px 10px;
  background: #222222;
  align-items: center;
}
.footer-logo{
  width: 15%;
}
.footer-copyright{
  width: 25%;
  color: white;
  font-size: 13px;
}
.footer-social{
  width: 35%;
  display: flex;
  justify-content: end;
}
.footer-social .social-icon{
  width: 20px;
  height: 20px;
  font-size: 13px;
}
.footer-poweredby{
  width: 25%;
}
.filiter-modal{
  border: 1px solid #29446438;
  color: #294464;
  background: white;
  position: absolute;
  top: 52px;
  right: 0;
  transform: translateX(-50%);
  width: 350px;
  max-width: 90%;
  border-radius: 3px;
  padding: 7px;
  box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 1102;
}
.fixed-footer{
  position: fixed;
  width: 100%;
  bottom: 0;
}
.bottom-footer{
  width: 100%;
}