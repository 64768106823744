body {
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

header {
  z-index: 1001;
  background-image: url('https://mapbeta.goeshow.com/asset/header_left.gif'),url('https://mapbeta.goeshow.com/asset/header_bg.gif');
  background-position: left top, left top;
  background-repeat: no-repeat, repeat;
  background-color: unset;
}
header .MuiToolbar-root{
  padding-left: 190px;
  min-height: 49px!important;
  height: 49px!important;
  align-items: end;
}
header .MuiToolbar-root > .MuiBox-root{
  margin-bottom: 1.5px;
}
header button, header a{
  min-width: 45px!important;
  padding: 1.5px 5px!important;
  line-height: 1.5!important;
  background: transparent!important;
  height: 26px;
}
header .letter-button{
  font-size: 16px!important;
  padding-top: 4px!important;
}
/* header button:hover, header a:hover{
  background: #2F4A69!important;
} */
header  .MuiButtonGroup-grouped:not(:last-of-type) {
  border-color: white!important;
}
.MuiTableCell-root{
  padding-top:7px;
  padding-bottom: 7px;
}
header button .MuiSvgIcon-root{
  width: 0.9em;
  height: 0.9em;
}
header svg{
  background-color: unset!important;
}
header .MuiButtonGroup-root{
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 8%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 3%);
}
#background-modal svg{
  background-color: unset!important;
}
.sideview{
  position: fixed;
  top: 49px!important;
  left: 0;
  z-index: 1004;
}
.siderightview{
  position: fixed;
  top: 55px!important;
  right: 7px!important;
  z-index: 1004;
}
.siderightview .MuiAccordion-root{
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}
.booth-list-slider .MuiDrawer-paper{
  height: calc(100vh - 49px)
}
.full-height-slider .MuiDrawer-paper{
  height: calc(100vh)
}
.main-container{
  display:flex;
  position: relative;
}
.toolbar{
  /* position: fixed;
  left:0;
  top:64px; */
  /* width: 170px; */
  height: 100%;
  z-index: 1000;
  /* background: #e0e0e0; */
  padding: 25px 10px;
  /* border-right: 3px solid #707070; */
}
.right-toolbar{
  /* position: fixed;
  left:0;
  top:64px; */
  /* width: 170px; */
  height: 100%;
  z-index: 1000;
  /* background: #e0e0e0; */
  padding: 25px 10px;
  /* border-right: 3px solid #707070; */
}
.canvas-viewer{ 
  padding-left: 0;
  padding-top:51px
}
.canvas-container{ 
  padding-left: 0;
  padding-top:0!important
}
[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  cursor: move;
}
.furniture{
  /* text-align: left; */
}
.mouseModal{
  position: fixed;
  background: #FEFFE1;
  border-radius: 10px;
  padding: 0;
  z-index: 10005;
  box-shadow: 1px 1px 3px rgb(37 37 37 / 47%);
  display: block;
  color: #101010;
  font-size: 15px;
  text-align: left;
  max-width: 800px;
  /* min-width: 0; */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s ease;
  background: #F5F5F5;
  color: #181818;
  max-height: 300px;
  overflow-y: hidden;
  /* width: 420px; */
  cursor: pointer;
}
.sub-exhibtors{
  background: #F5F5F5;
}
.mouseModal p:last-child{
  margin-bottom: 0;
}
.mouseModal.active {
  visibility: visible;
  opacity: 1;
}

.mouseModal p{
  margin-top: 0;
  margin-bottom: 3px;
}
.mouseModal h4{
  margin-top: 10px;
  margin-bottom: 5px;
}
.loader {
  --b: 8px;  /* border thickness */
  --n: 15;    /* number of dashes*/
  --g: 10deg; /* gap between dashes*/
  --c: red;   /* the color */

  width: 35px; /* size */
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 0;
  background: conic-gradient(#ff000033,var(--c)) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,
       #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
       #0000     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
    radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)));
          mask:
    repeating-conic-gradient(#0000 0deg,
       #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
       #0000     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
    radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation: load 1.5s infinite steps(var(--n));
}
@keyframes load {to{transform: rotate(1turn)}}


.mouseCursorModal{
  position: fixed;
  padding: 0;
  z-index: 10005;
  max-width: 30px;
  height: 30px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.6s, opacity 0.6s ease-out;
  background: transparent;
}
.mouseCursorModal.active {
  visibility: visible;
  opacity: 1;
}

.chip{
    max-width: 100%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.7125rem;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 20px;
    color: #fff;
    background-color: #1976d2;
    border-radius: 16px;
    white-space: nowrap;
    -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    border: 0;
    padding: 0;
    vertical-align: middle;
    box-sizing: border-box;
    margin-bottom: 3px;
    margin-right: 3px;
}

.chip span{ 
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}
.disabled{
  opacity: 0.5;
}
.enabled{
  opacity: 1!important;
}
.layertext .MuiTypography-root{
  font-size: 12px;
  word-break: break-word;
}
.layeravada {
  min-width: 0 !important;
  margin-right: 15px;
}
.boot-cat-select{
  box-shadow:none!important;
}
.render-booth-container{
  margin:auto
}
.sketch-picker{
  margin: auto;
  width: 300px!important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px!important;
}
div[id^=font-picker] .dropdown-button {
  background: #b1b1b130!important;
}

.small.MuiSpeedDial-root button{
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    /* border-radius: 0; */
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    /* min-width: 64px; */
    padding: 4px 5px;
    /* border-radius: 4px; */
    /* -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    color: #2e7d32;
    color: white;
    background-color: transparent;
    box-shadow: none;
}
.small.MuiSpeedDial-root button:hover {
  background-color: #22222250;
}
.small.MuiSpeedDial-root .MuiSpeedDial-fab{
  width:40px;
  height:40px;
}

.price-modal .MuiTableCell-root{
  border-right: 1px solid rgb(187, 187, 187);
  border-left: 1px solid rgb(187, 187, 187);
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding:10px
}
.price-modal .MuiTableRow-root:last-child td, .price-modal .MuiTableRow-root:last-child th {
  border-right: 1px solid rgb(187, 187, 187);
  border-left: 1px solid rgb(187, 187, 187);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
}
.color-table thead tr th{
  background-color: #4E7090;
  color:white
}
.color-table tbody tr th{
  background-color: #a4c0da;
}
.sponsor-table thead tr th{
  background-color: #4E7090;
  color:white
}
.group-pannel > .MuiBox-root{
  padding-left: 0;
  padding-right: 0;
}
.group-pannel .subtitle{
  background-color: #4E7090;
  color:white;
  padding: 5px 10px
}
.item-label{
  display:flex;
  align-items:center;
  padding-left: 10px;
  background-color: #a4c0da;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.item-field{
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.group-color-picker{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index: 100000000
}
.item-color{
  display:flex;
  align-items: center;
}
.color-icon{
  width:60px;
  height:30px;
  border:2px solid rgba(224, 224, 224, 1);
  background:rgba(224, 224, 224, 1);
  margin-right: 5px;
}

.pricing-table  thead tr th{
  background-color: #4E7090;
  color:white
}
/* .pricing-table  tbody tr th{
  background-color: #a4c0da;
} */
.MuiButton-root {
  text-transform:unset!important
}
.MuiButtonBase-root{
  text-transform:unset!important
}
.preview-image{
  width:150px;
  height:150px;
  border: 1px solid #e1e1e1;
    margin-top: 10px;
}
.preview-image img{
  width: 100%;
  height: 100%;
  object-fit:cover
}
.preview-contain-image{
  width:150px;
  height:150px;
  border: 1px solid #e1e1e1;
    margin-top: 10px;
  display:flex;
}
.preview-contain-image img{
  width: 100%;
  object-fit:contain
}

.MuiDialogTitle-root {
  background: #3b6c9b;
  color: white;
  padding: 13px 24px!important;
}
.admin.carousel-item{
  width: 180px;
}
.carousel-item{
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
  margin: 10px;
  height:150px
}
.carousel-item img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.carousel-item .video{
  object-fit: cover;
  width: 100%!important;
  height: 100%!important;
}
.company-profile-viewer .video{
  width: 359px!important;
  height: 202px!important;
}
.company-profile-viewer .bg{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position:relative;
}
.carousel-delbtn{
  position: absolute!important;
  top: 4px;
  right: 4px;
  z-index: 10000;
  background: white!important;
  width: 26px;
  height: 26px;
}
.carousel-item video{
  object-fit: cover;
}
/* .company-profile-viewer .bg::after{
  content:'';
  width: 100%;
  height:100%;
  position: absolute;
  left:0;
  top:0;
  opacity:0.5;
  background:white
} */
.bg .bgImg{
  object-fit: cover;
    position: absolute;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.company-profile-viewer .carousel-item {
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
  margin: 0;
  height: 300px;
}
.company-profile-viewer .react-multi-carousel-list{
  padding-bottom:20px
}
.categoryBox{
  max-height: 400px;
  overflow-y: auto;
}
.backgroundBg{
  background-repeat: no-repeat;
  background-size: cover;
}


.leftMenuItem {
  padding: 0.5rem 1rem;
  min-height: 2rem;
  display: block;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #000 !important;
  text-decoration: none !important;
  cursor:pointer
}
.sideview .MuiListItem-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.sideview .MuiList-root {
  padding-top: 0;
}

.appLeftSearch .MuiListItem-root{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12)
}
.appLeftSearch .MuiList-root{
  padding-top: 0;
}
.buttonLink{
  padding: 0!important;
  font-size: 0.875rem!important;
  background: none!important;
  transition: color ease 0.3s!important;
  font-weight: 400!important;
  text-transform: none!important;
}
.buttonLink:hover {
  color: rgb(21, 101, 192)!important;
  background: none!important;
}
.leftCategoryLine{
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 7px;
  padding-top: 7px;
  cursor: pointer;
  list-style: none;
  display:flex
}

.leftCategoryLine::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: rgb(165, 164, 164); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}


.main-cat{
  font-weight: bold;  
}
.main-cat.leftCategoryLine::before {
  content: none!important
}
.leftPrimaryTxt{
  padding: 0 0.1rem 0 0rem;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leftMenuItem:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
}
.leftCategoryLine:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
}
.draw-option{
  border: 1px solid #29446438;
  color: #294464;
  background: white;
  position: fixed;
  top: 70px;
  right: 50px;
  width: 350px;
  border-radius: 3px;
  padding: 7px;
  box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.booth-sequence-option{
  border: 1px solid #29446438;
  color: #294464;
  background: white;
  position: fixed;
  top: 435px;
  right: 26px;
  width: 350px;
  border-radius: 3px;
  padding: 7px;
  box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 1102;
}

.legend-option{
  /* border: 1px solid #29446438; */
  color: #294464;
  background: white;
  position: fixed;
  top: 57px;
  right: 200px;
  width: 400px;
  border-radius: 3px;
  padding: 7px;
  z-index: 1101;
  box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.legend-content{
  max-height: 500px;
  overflow-y: auto;
}

.legend-color{
  width: 15px;
  height:15px;
  border-radius:50%;
  margin: auto
}


.draw-buttons button{
  color:#324752  ;
  min-width: 45px!important;
  margin: 0 5px
}

.draw-buttons button.active{
  color:white
}

.pulse{
  /* width: 100px;
  height: 100px; */
  background: rgb(255, 0, 0);
  border-radius: 2px;
  color: #FFF;
  text-align: center;
  /* line-height: 100px; */
  font-family: sans-serif;
  text-transform: uppercase;
  animation: animate-pulse 3s linear infinite;
  cursor: pointer
}
@keyframes animate-pulse{
  0%{
      box-shadow: 0 0 0 0 rgba(255, 0, 0,0.7),  0 0 0 0 rgba(255, 0, 0,0.7);
  }
  50%{
      box-shadow: 0 0 0 4px rgba(255, 0, 0,0.0),  0 0 0 4px rgba(255, 0, 0,0.5);
  }
  100%{
      box-shadow: 0 0 0 0 rgba(255, 0, 0,0.0),  0 0 0 4px rgba(255, 0, 0,0);
  }
  
}

.import {
  position: absolute!important;
  left: unset!important;
  top: unset!important;
  right: 13px!important;
  bottom: 64px!important;
}

.pan button{
  box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
  border-radius: 2px;
}

.main-control {
  position: absolute!important;
  left: 55px!important;
  top: 105px!important;
}

.company-dropdown{
  max-height: 300px;
  overflow-y: auto;
  position: a;
  position: absolute;
  background: white;
  width: 100%;
  z-index: 1000000;
  border: 1px solid #a0a0a0;
  border-radius: 3px;
  top: 12px;
  left: 0;
}

#indoors-grid-canvas{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

#bg-img-canvas{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}

#bg-canvas{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -3;
}

.circle .cropper-view-box{
  border-radius: 50%;
}

.company-tile{
  box-shadow: 0px 1px 3px rgb(0 0 0 / 12%);
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  cursor: pointer;
}
.company-tile.selected{
  box-shadow: 0px 1px 3px rgb(255 0 0 / 12%);
  border: 2px solid #fb5757;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
  transition: opacity 2s ease-out;
}

.import-bg-note{
    font-weight: bold;
    color: #d30a2c;
    text-align: center;
    font-size: 13px;
}

.company-profile-viewer .menu-btn{
  /* position: fixed;
  left: 5px;
  top: 70px; */
  margin-left: 0;
  display: block;
  text-align: center;
  background: #fafafa;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
}

.company-profile-viewer .menu-btn:hover{
  background-color: #d5d5d5;
}
.search-attach{
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
  height: 100%;
  min-width: 80px;
}
.search-bar fieldset{
  display: none;
}

.key-select .MuiSelect-select{
  padding-top: 8px;
  padding-bottom: 8px;
}

.booth-drawer{

}

.booth-drawer {
  /* border: 1px solid #29446438; */
  color: #294464;
  background: white;
  position: fixed;
  bottom: 57px;
  right: 80px;
  width: 350px;
  border-radius: 3px;
  padding: 7px;
  z-index: 10006;
  box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.color-table thead tr th .Mui-active{
  color: white;
}
.MuiTableSortLabel-icon{
  color: white!important;
}
.sales-row{
  padding: 12px;
  margin-bottom: 20px;
  box-shadow: none!important;
  border: 1px solid #00000040;
}

.side-home .MuiTypography-root{
  font-weight: bold!important;
}

.Client .main-container{
  position: relative;
}

.Client .sub-header{
  position: absolute;
  top: 65px;
  /* height: 51px; */
  /* width: 100%; */
  left: 0px;
  /* width: calc(100% - 50px); */
  background: transparent;
  /* border-bottom: 1px solid #e0e0e0; */
  /* padding-top: 3px; */
  margin: 5px 0 0 5px;
  display:flex;
  align-items: center;
  padding-left: 20px;
  z-index: 10;
}
.Client .sub-header img{
  margin-right: 10px;
}

.play-header{
  position: absolute;
  top: 3px;
  height: 51px;
  /* width: 200px!important; */
  right: 0;
  /* width: calc(100% - 50px); */
  background: transparent;
  padding-top: 3px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  z-index: 10000000000;
}

.play-slider{
  position: absolute;
  bottom: 6px;
  height: 35px;
  /* width: 200px!important; */
  left: 50%;
  /* width: calc(100% - 50px); */
  background: transparent;
  padding-top: 3px;
  margin: 0 15px;
  z-index: 10000000000;
  width: 500px;
  transform: translateX(-50%);
}
@media (max-width: 900px) {
  .Client .sub-header{
    margin: 5px 0 0 5px;
    padding-left: 0;
    /* width: 100%; */
  }
  .Client .sub-header img{
    margin-right: 0;
  }
}

@media (max-width: 500px) {
  .Client .sub-header button{
    min-width: 45px;
  }
}

.Client .sub-header .MuiButton-root{
  color: #333333;
}

.Client .sub-footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 1px;
  width: calc(100vw - 10px - 64px);
  background: white;
  border-top: 1px solid #e0e0e0;
  padding-top: 3px;
}


.Client .MuiInput-underline:before {
  border-bottom: none;
}

.leftpanel .MuiDrawer-root>.MuiPaper-root{
  position: unset;
}
.MuiPagination-ul{
  justify-content: center;
}
.MuiDialog-root{
  z-index: 10000!important;
}
.MuiModal-root.MuiPopover-root.MuiMenu-root{
  
  z-index: 10006!important;
}
#dropdown-menu1{
  z-index: 1005!important;

}
#dropdown-menu-setup{
  z-index: 1005!important;
}
#dropdown-menu-view{
  z-index: 1005!important;
}
#dropdown-boot-open{
z-index: 1005!important;
}
.MuiAutocomplete-popper{
  z-index: 10001!important;
}
.top-modal{
  z-index: 1000001!important;
}
.ui-video-seek-slider .track .main {
  background: #bbbbbb;
}
.ui-video-seek-slider .thumb .handler {
  background-color: #3d3d3d!important;
}
.ui-video-seek-slider .track .main:before {
  background-color: rgba(255,255,255,.2)!important;
}

.ui-video-seek-slider .hover-time {
  display: none!important;
}
.ui-video-seek-slider .track .main .connect {
  background-color: #878787!important;
}
.mouse-over-showmore{
  font-size: 12px;
  color:#4770AC;
}
.sub-exhibtor-edit-btn{
  font-size: 13px;
    font-weight: bold;
    text-decoration: auto;
    color: #0069aa;
}
.sub-exhibtor-title{
  /* text-decoration: auto; */
  color: inherit;
}
.overview-modal-convas{
  text-align: center;
}

.overview-modal-convas canvas{
  margin: auto;
}